import { useState } from "react";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebaseConfig";
const Footer = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);

    if (!email.includes("@")) {
      setMessage("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    try {
      // Check if email already exists
      const emailQuery = query(collection(db, "newsletters"), where("email", "==", email));
      const existingEmails = await getDocs(emailQuery);

      if (!existingEmails.empty) {
        setMessage("You're already subscribed!");
        setLoading(false);
        return;
      }

      // Add email to Firestore
      await addDoc(collection(db, "newsletters"), { email, createdAt: new Date() });
      setMessage("Thank you for subscribing!");
      setEmail("");
    } catch (error) {
      console.error("Error signing up:", error);
      setMessage("Something went wrong. Please try again.");
    }
    setLoading(false);
  };
  return (
    <nav className="navbar border-top p-4" style={{ backgroundColor: '#000', color: '#fff' }}>
      <div className="container text-center text-lg-start">
        {/* Grid Layout for Footer */}
        <div className="row d-flex justify-content-end">

          {/* Brand & Contact Info */}
          <div className="col-lg-3 text-center text-lg-start mb-3 mb-lg-0">
            <p className="fs-1 fw-bold" style={{ fontFamily: "'Rakkas', cursive" }}>Fitealthy</p>
            <p className="fs-5 m-0" >info@fitealthy.com</p>
            <p className="fs-5 m-0" >(431) 557-4644</p>
          </div>

          {/* Navigation Links Column 1 */}
          <div className="col-lg-3 text-center mt-2" >
            <ul className="list-unstyled">
              {[
                { name: "About", path: "/about" },
                { name: "Articles", path: "/vegan-nutrition-articles" },
                { name: "Write for us", path: "/write-for-us-vegan" },
                { name: "Discussion Forum", path: "/discussion-Forum" },
              ].map((item, index) => (
                <li key={index}>
                  <a href={item.path} className="text-white text-decoration-none d-block py-1" style={{ fontSize: '18px', transition: 'color 0.3s' }}>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Navigation Links Column 2 */}
          <div className="col-lg-3 text-center mt-2">
            <ul className="list-unstyled">
              {[
                { name: "Blog", path: "/blog" },
                { name: "Vegan Stats", path: "/vegan-statistics" },
                { name: "Vegan Recipes", path: "/vegan-recipes" },
                { name: "Vegan Cafes", path: "/vegan-restaurants-cafes" },
              ].map((item, index) => (
                <li key={index}>
                  <a href={item.path} className="text-white text-decoration-none d-block py-1" style={{ fontSize: '18px', transition: 'color 0.3s' }}>
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>


          {/* Newsletter Subscription */}
          <div className="col-lg-3 text-center">
            <h4 className="mb-3" style={{  color: '#fff' }}>Don't miss out!</h4>
            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center gap-2">
              <input
                type="email"
                className="form-control w-100"
                style={{ maxWidth: "400px" }}
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="btn px-4 w-100" style={{ backgroundColor: '#F8C630' }} disabled={loading}>
                {loading ? "Subscribing..." : "Subscribe"}
              </button>
            </form>
            {message && <p className="mt-2 text-muted">{message}</p>}
          </div>

        </div>
      </div>
    </nav>



  );
};

export default Footer;
