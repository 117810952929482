// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDeL2cO9_CcX6sIaLPEueXaBAta0n0v6kA",
  authDomain: "gymbud-2442c.firebaseapp.com",
  projectId: "gymbud-2442c",
  storageBucket: "gymbud-2442c.firebasestorage.app",
  messagingSenderId: "442610020540",
  appId: "1:442610020540:web:932d0844b3a439004b60b9",
  measurementId: "G-DJV1XL1ZFG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth, Firestore, and Storage for web
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };
