import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg border-bottom">
      <div className="container d-flex justify-content-between align-items-center">
        {/* Logo on the Left */}
        <Link className="navbar-brand" to="/">
          <img src="/logo-short.jpeg" className="img-fluid" style={{ maxWidth: "80px", height: "auto" }} alt="Fitealthy Logo" />
        </Link>


        {/* Navbar Toggle Button (for mobile view) */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navigation Links on the Right */}
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className="nav-link" to="/auth">
                <p className="fs-6" >Create Post</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">
                <p className="fs-6" >About Us</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/write-for-us-vegan">
                <p className="fs-6" >Write For Us</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/vegan">
                <p className="fs-6" >Vegan</p>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/vegan-nutrition-articles">
                <p className="fs-6" >Articles</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  );
};

export default Navbar;
