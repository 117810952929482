import "./css/bootstrap.min.css";
import 'quill/dist/quill.snow.css';


import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Suspense, lazy, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import ReactGA from "react-ga4";

// Lazy-loaded components for performance

const Blogpage = lazy(() => import('./Blogpage'));
const BlogPost = lazy(() => import('./BlogPost'));
const AdminPage = lazy(() => import('./AdminPage'));
const About = lazy(() => import('./About'));
const Articles = lazy(() => import('./Articles'));
const Vegan = lazy(() => import('./Vegan'));
const VeganRecipes = lazy(() => import('./Recipes'));
const VeganCafes = lazy(() => import('./VeganCafes'));
const VeganStats = lazy(() => import('./VeganStats'));
const WriteForUs = lazy(() => import('./WriteForUs'));
const AuthForm = lazy(() => import('./AuthForm'));
const DiscussionForum = lazy(() => import('./DiscussionForum'));
// Initialize Google Analytics with your Measurement ID
ReactGA.initialize("G-DJV1XL1ZFG"); // Replace with your GA4 Measurement ID

const AnalyticsTracker = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send("pageview", { page_path: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Helmet>

            <title>Fitealthy: Fitness Plans & Vegan Nutrition (2025 Guide)</title>
            <meta name="description" content="Get personalized workout plans + 500+ vegan recipes. Join 50K+ users transforming their health with science-backed fitness & nutrition strategies." />
            <meta name="keywords" content="AI fitness planner, vegan meal plans, plant-based recipes, workout tracker, nutrition coaching" />
            <script type="application/ld+json">
             {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Fitealthy",
              "url": "https://www.fitealthy.com/",
              "description": "Your trusted source for plant-based nutrition and fitness guidance",
              "founder": {
                "@type": "Person",
                "name": "Aman Singh"
              },
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://www.fitealthy.com/search?q={search_term_string}"
                },
                "query-input": "required name=search_term_string"
              },
              "hasPart": [
                {
                  "@type": "WebPage",
                  "name": "Beginner's Guide to Plant-Based Living",
                  "url": "https://www.fitealthy.com/vegan",
                  "description": "Step-by-step guide to transitioning to a vegan lifestyle",
                  "keywords": "vegan transition, plant-based diet, cruelty-free living"
                },
                {
                  "@type": "WebPage",
                  "name": "Write for us vegan",
                  "url": "https://www.fitealthy.com/write-for-us-vegan",
                  "description": "Share your expertise in plant-based nutrition and fitness"
                },
                {
                  "@type": "CollectionPage",
                  "name": "Science-Backed Nutrition Research",
                  "url": "https://www.fitealthy.com/vegan-nutrition-articles",
                  "description": "Evidence-based articles on plant-based health benefits"
                },
                {
                  "@type": "CollectionPage",
                  "name": "Protein-Packed Vegan Recipes",
                  "url": "https://www.fitealthy.com/vegan-recipes",
                  "keywords": "high-protein vegan meals, plant-based recipes, meatless dishes"
                },
                {
                  "@context": "https://schema.org/",
                  "@type": "Dataset",
                  "name": "2025 Vegan Lifestyle Statistics",
                  "url": "https://www.fitealthy.com/vegan-statistics",
                  "description": "Latest data on veganism growth and environmental impact",
                  "license": "https://creativecommons.org/licenses/by/4.0/",
                  "creator": {
                    "@type": "Organization",
                    "name": "Fitealthy",
                    "url": "https://www.fitealthy.com"
                  }
                },
                {
                  "@type": "MedicalScholarlyArticle",
                  "name": "Health Benefits of Plant-Based Diets",
                  "url": "https://www.fitealthy.com/vegan-health-benefits",
                  "keywords": "vegan heart health, diabetes prevention, plant-based nutrition"
                }
              ]
            })}
            </script>
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  { "@type": "ListItem", "position": 1, "name": "Home", "item": "https://Fitealthy.com" },
                  { "@type": "ListItem", "position": 2, "name": "Vegan", "item": "https://Fitealthy.com/vegan" },
                  { "@type": "ListItem", "position": 3, "name": "Vegan Recipes", "item": "https://Fitealthy.com/vegan-recipes" },
                  { "@type": "ListItem", "position": 4, "name": "Vegan Statistics", "item": "https://Fitealthy.com/vegan-statistics" },

                ]
              })}
            </script>
          </Helmet>

          <Navbar />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Blogpage />} />
              <Route path="/auth" element={<AuthForm />} />
              <Route path="/post/:slug" element={<BlogPost />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/vegan-nutrition-articles" element={<Articles />} />
              <Route path="/about" element={<About />} />
              <Route path="/vegan" element={<Vegan />} />
              <Route path="/vegan-recipes" element={<VeganRecipes />} />
              <Route path="/vegan-restaurants-cafes" element={<VeganCafes />} />
              <Route path="/vegan-statistics" element={<VeganStats />} />
              <Route path="/discussion-Forum" element={<DiscussionForum />} />
              <Route path="/write-for-us-vegan" element={<WriteForUs />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
          <Footer />
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
